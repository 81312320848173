import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import styles from "../styles/Calendly.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Calendly: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.contactUsLayout}>
      {/* Sección de Calendly a la izquierda */}
      <div className={styles.calendlyContainer}>
        <h2>{t("companies-landing-page.calendly-title-black")}</h2>
        <p>
          <span>{t("companies-landing-page.calendly-paragraph-black")}</span>
          {t("companies-landing-page.calendly-paragraph-normal")}
        </p>

        <iframe
          src="https://calendly.com/relotu/45min"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Calendly;

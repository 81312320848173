// HomeVideoText.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/Home styles/HomeVideoText.module.css";
import {
  faLaptopCode,
  faSuitcase,
  faHome,
  faThumbsUp,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const HomeVideoText = () => {
  const [t, i18n] = useTranslation("global");
  const videoLink =
  i18n.language === "de" ? "A3jB11uyAzk?si=3YY1b4tT_v_sJHbO" : "bCqamEve84A?si=mZW0AJ3VenoxVarV";

  return (
    <div className={styles.videoTextContainer}>
      <div className={styles.videoContainer}>
        <iframe
          src={`https://www.youtube.com/embed/${videoLink}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.textContainer}>
        <h1>
          {t("home.text-video-title-black")}{" "}
          <span className={styles.highlighted}>
            {t("home.text-video-title-blue")}{" "}
          </span>
          {t("home.text-video-title-black2")}
        </h1>
        <p>{t("home.text-video-paragraph")}</p>
        <ul>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faLaptopCode} />{" "}
            <span>{t("home.text-video-list1-bold")}</span>{" "}
            {t("home.text-video-list1-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faSuitcase} />{" "}
            <span>{t("home.text-video-list2-bold")} </span>{" "}
            {t("home.text-video-list2-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faThumbsUp} />{" "}
            <span>{t("home.text-video-list3-bold")}</span>{" "}
            {t("home.text-video-list3-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faHome} />{" "}
            <span>{t("home.text-video-list4-bold")}</span>{" "}
            {t("home.text-video-list4-normal")}
          </p>
          <p>
            {" "}
            <FontAwesomeIcon className={styles.svg} icon={faGift} />{" "}
            <span>{t("home.text-video-list5-bold")}</span>{" "}
            {t("home.text-video-list5-normal")}
          </p>
        </ul>
      </div>
    </div>
  );
};

export default HomeVideoText;

/* <h1>Your Digital Relocation Manager & Partner for Life</h1>
        <p>
          Finli is your digital relocation manager that stays with you even
          after the move. Our cross-country expertise allows us to assist you in
          your new and old home country so you do not miss out on anything
          important to take care of. Even better, we take care of all the admin
          tasks and stay at your side after your move.
        </p> */

/* https://www.youtube.com/embed/k6EpJCmBda4?si=pexevjruLmZnjk5b */
